<template>
  <v-sheet v-bind="propsCompSheet" data-cy="organisations-create">
    <div class="mb-4">
      <v-form :disabled="formDisabled" @submit.prevent>
        <div class="formField">
          <span class="reqMark">*</span>
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('common.name')"
            v-model="editName"
            :error-messages="editNameErrors"
            @input="$v.editName.$touch()"
            @blur="$v.editName.$touch()"
            type="text"
          >
          </v-text-field>
        </div>

        <div class="formField">
          <div>
            <div class="label-show">
              {{ $t("organisations.levels.label") }}:
            </div>
            <OrganisationLevelFieldRO
              :levelValue="editLevel"
            ></OrganisationLevelFieldRO>
          </div>
        </div>

        <div class="formField">
          <v-file-input
            v-bind="propsFormFields"
            :label="$t('common.logo')"
            show-size
            v-model="editUploads"
          >
          </v-file-input>
        </div>

        <div class="formField">
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('common.address')"
            v-model="editAddress"
            :error-messages="editAddressErrors"
            @input="$v.editAddress.$touch()"
            @blur="$v.editAddress.$touch()"
            type="text"
          >
          </v-text-field>
        </div>

        <div class="formField">
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('common.city')"
            v-model="editCity"
            :error-messages="editCityErrors"
            @input="$v.editCity.$touch()"
            @blur="$v.editCity.$touch()"
            type="text"
          >
          </v-text-field>
        </div>

        <div class="formField">
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('common.postalCode')"
            v-model="editPostalCode"
            :error-messages="editPostalCodeErrors"
            @input="$v.editPostalCode.$touch()"
            @blur="$v.editPostalCode.$touch()"
            type="text"
          >
          </v-text-field>
        </div>

        <div class="formField">
          <CountryPickerSelect
            :pickerValue="editCountryIso3166_a2"
            @pickerChanged="
              editCountryIso3166_a2 = $event;
              $v.editCountryIso3166_a2.$touch();
            "
            @blur="$v.editCountryIso3166_a2.$touch()"
            :errorMessages="editCountryIso3166_a2Errors"
          >
          </CountryPickerSelect>
        </div>

        <div class="formField">
          <LanguagePickerSelect
            :pickerValue="editLanguageIso639_1"
            @pickerChanged="
              editLanguageIso639_1 = $event;
              $v.editLanguageIso639_1.$touch();
            "
            @blur="$v.editLanguageIso639_1.$touch()"
            :errorMessages="editLanguageIso639_1Errors"
          >
          </LanguagePickerSelect>
        </div>

        <div class="formField">
          <v-textarea
            v-bind="propsFormFields"
            :label="$t('common.description')"
            v-model="editDescription"
            :error-messages="editDescriptionErrors"
            @input="$v.editDescription.$touch()"
            @blur="$v.editDescription.$touch()"
            type="text"
          >
          </v-textarea>
        </div>

        <div class="formField">
          <v-checkbox
            v-bind="propsFormFields"
            v-model="editNotifyCreate"
            :label="$t('organisations.notifyCreate')"
          ></v-checkbox>
        </div>

        <div class="formField">
          <v-checkbox
            v-bind="propsFormFields"
            v-model="editNotifyUpdate"
            :label="$t('organisations.notifyUpdate')"
          ></v-checkbox>
        </div>

        <div class="formField">
          <v-checkbox
            v-bind="propsFormFields"
            v-model="editNotifyRead"
            :label="$t('organisations.notifyRead')"
          ></v-checkbox>
        </div>

        <div class="formField">
          <v-checkbox
            v-bind="propsFormFields"
            v-model="editNotifyDownload"
            :label="$t('organisations.notifyDownload')"
          ></v-checkbox>
        </div>

        <div class="formField">
          <v-checkbox
            v-bind="propsFormFields"
            v-model="editNotifyDelete"
            :label="$t('organisations.notifyDelete')"
          ></v-checkbox>
        </div>

        <div class="formField">
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('organisations.maxFileSize')"
            v-model="editMaxFileSize"
            :error-messages="editMaxFileSizeErrors"
            @input="$v.editMaxFileSize.$touch()"
            @blur="$v.editMaxFileSize.$touch()"
            type="text"
          >
          </v-text-field>
          <div class="label-show">
            {{ $t("organisations.fileSizeAllowedMult") }}:
          </div>
        </div>

        <div v-if="hasDescendants" class="formField">
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('organisations.resellerMaxFileSize')"
            v-model="editMaxResellerFileSize"
            :error-messages="editMaxResellerFileSizeErrors"
            @input="$v.editMaxResellerFileSize.$touch()"
            @blur="$v.editMaxResellerFileSize.$touch()"
            type="text"
          >
          </v-text-field>
          <div class="label-show mb-4">
            {{ $t("organisations.fileSizeAllowedMult") }}:
          </div>
        </div>

        <div class="formField">
          <span class="reqMark">*</span>
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('common.adminUserName')"
            v-model="editAdminUserName"
            :error-messages="editAdminUserNameErrors"
            @input="$v.editAdminUserName.$touch()"
            @blur="$v.editAdminUserName.$touch()"
            class="mt-8"
            type="text"
          >
          </v-text-field>
        </div>

        <div class="formField">
          <span class="reqMark">*</span>
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('common.email')"
            v-model="editAdminEmail"
            :error-messages="editAdminEmailErrors"
            @input="$v.editAdminEmail.$touch()"
            @blur="$v.editAdminEmail.$touch()"
            type="text"
          >
          </v-text-field>
        </div>

        <div class="formField">
          <span class="reqMark">*</span>
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('common.password')"
            v-model="editAdminPassword"
            :error-messages="editAdminPasswordErrors"
            @input="$v.editAdminPassword.$touch()"
            @blur="$v.editAdminPassword.$touch()"
            type="text"
          >
          </v-text-field>
        </div>
      </v-form>
    </div>

    <div class="d-flex">
      <div class="flex-grow-1"></div>
      <v-btn
        v-if="authCan('create.organisations')"
        v-bind="propsButtonAction"
        :loading="status.loading"
        :disabled="!formReadyForSubmit"
        @click="clickCreate"
        data-cy="button-create"
        >{{ $t("common.create") }}</v-btn
      >
    </div>
  </v-sheet>
</template>

<script>
import APP_CONSTANTS from "@/appConstants";
import Permissions from "@/modules/auth/mixins/Permissions.mixin.js";
import MiscUtils from "@/modules/base/miscUtils.mixin";
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import FormCommonErrors from "@/modules/base/formCommonErrors.mixin";
import OrganisationLevelFieldRO from "@/modules/organisations/bits/OrganisationLevelFieldRO";
import CountryPickerSelect from "@/modules/base/bits/CountryPickerSelect";
import LanguagePickerSelect from "@/modules/base/bits/LanguagePickerSelect";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "OrganisationsCreate",
  mixins: [Permissions, MiscUtils, ComponentStatus, FormCommonErrors],
  components: {
    OrganisationLevelFieldRO,
    CountryPickerSelect,
    LanguagePickerSelect,
  },
  data: () => ({
    firstFormClick: true,
    // New proxy form data
    editName: "",
    editLevel: null,
    editAddress: "",
    editCity: "",
    editPostalCode: "",
    editDescription: "",
    editCountryIso3166_a2: "",
    editLanguageIso639_1: "",

    editUploads: undefined,

    editNotifyCreate: false,
    editNotifyUpdate: false,
    editNotifyRead: false,
    editNotifyDownload: false,
    editNotifyDelete: false,

    editMaxFileSize: null,
    editMaxResellerFileSize: null,

    editAdminUserName: "",
    editAdminEmail: "",
    editAdminPassword: "",
  }),
  validations: {
    editName: {
      required,
      maxLength: maxLength(1000),
    },
    editLevel: {
      required,
    },
    editAddress: {
      maxLength: maxLength(1000),
    },
    editCity: {
      maxLength: maxLength(1000),
    },
    editPostalCode: {
      maxLength: maxLength(100),
    },
    editCountryIso3166_a2: {},
    editLanguageIso639_1: {},
    editDescription: {},
    editMaxFileSize: {},
    editMaxResellerFileSize: {},
    editNotifyCreate: {},
    editNotifyUpdate: {},
    editNotifyRead: {},
    editNotifyDownload: {},
    editNotifyDelete: {},
    editAdminUserName: {
      required,
      maxLength: maxLength(1000),
    },
    editAdminEmail: {
      required,
      maxLength: maxLength(1000),
    },
    editAdminPassword: {
      required,
      maxLength: maxLength(1000),
    },
    editUploads: {},
  },
  computed: {
    formDisabled() {
      // if (this.isAdmin) {
      //   return false;
      // }
      return false;
    },
    formReadyForSubmit() {
      if (this.status.loading) {
        return false;
      }
      if (this.status.readError) {
        return false;
      }
      // if (this.isAdmin) {
      //   return true;
      // }
      if (this.firstFormClick) {
        return true;
      }
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$anyDirty) {
        return false;
      }
      return true;
    },
    editLevelErrors() {
      const errors = [];
      if (!this.$v.editLevel.$dirty) return errors;
      if (!this.$v.editLevel.required) {
        errors.push(this.$t("common.errors.required"));
      }
      return errors;
    },
    editMaxFileSizeErrors() {
      const errors = [];
      // if (!this.$v.editLevel.$dirty) return errors;
      // if (!this.$v.editLevel.required) {
      //   errors.push(this.$t("common.errors.required"));
      // }
      return errors;
    },
    editMaxResellerFileSizeErrors() {
      const errors = [];
      // if (!this.$v.editLevel.$dirty) return errors;
      // if (!this.$v.editLevel.required) {
      //   errors.push(this.$t("common.errors.required"));
      // }
      return errors;
    },
    editAdminUserNameErrors() {
      const errors = [];
      if (!this.$v.editAdminUserName.$dirty) return errors;
      if (!this.$v.editAdminUserName.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editAdminUserName.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editAdminEmailErrors() {
      const errors = [];
      if (!this.$v.editAdminEmail.$dirty) return errors;
      if (!this.$v.editAdminEmail.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editAdminEmail.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editAdminPasswordErrors() {
      const errors = [];
      if (!this.$v.editAdminPassword.$dirty) return errors;
      if (!this.$v.editAdminPassword.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editAdminPassword.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    hasDescendants() {
      if (this.editLevel === APP_CONSTANTS.ORGANISATION_LEVELS_RESELLER) {
        return true;
      }
      return false;
    },
  },

  created() {
    let currentLevel = this.$store.getters["auth/getOrganisationLevel"];
    if (currentLevel === APP_CONSTANTS.ORGANISATION_LEVELS_SYSTEM) {
      this.editLevel = APP_CONSTANTS.ORGANISATION_LEVELS_RESELLER;
    } else if (currentLevel === APP_CONSTANTS.ORGANISATION_LEVELS_RESELLER) {
      this.editLevel = APP_CONSTANTS.ORGANISATION_LEVELS_COMPANY;
    } else if (currentLevel === APP_CONSTANTS.ORGANISATION_LEVELS_COMPANY) {
      this.editLevel = APP_CONSTANTS.ORGANISATION_LEVELS_COMPANY_CLIENT;
    }
  },

  methods: {
    clickCreate() {
      if (this.firstFormClick) {
        this.firstFormClick = false;
        this.$v.$touch();
      }
      if (!this.$v.$invalid) {
        if (this.editUploads) {
          this.sendCreateWithFile();
        } else {
          this.sendCreate();
        }
      }
    },
    sendCreate(fileEvent = null) {
      this.setStatusCreating();
      let payload = {
        name: this.editName,
        level: this.editLevel,
        address: this.editAddress,
        city: this.editCity,
        postalCode: this.editPostalCode,
        countryIso3166_a2: this.editCountryIso3166_a2,
        languageIso639_1: this.editLanguageIso639_1,
        description: this.editDescription,
        notifyCreate: this.editNotifyCreate,
        notifyUpdate: this.editNotifyUpdate,
        notifyRead: this.editNotifyRead,
        notifyDownload: this.editNotifyDownload,
        notifyDelete: this.editNotifyDelete,
        userName: this.editAdminUserName,
        email: this.editAdminEmail,
        password: this.editAdminPassword,
        passwordConfirmation: this.editAdminPassword,
      };
      if (fileEvent) {
        let file = fileEvent.target.result;

        payload.file = file;
        payload.fileName = this.editUploads.name;
        payload.fileType = this.editUploads.type;
      }
      if (this.editMaxFileSize) {
        let newMaxFileSize = this.parseDiskSize(this.editMaxFileSize);
        if (!isNaN(newMaxFileSize)) {
          payload.maxFileSize = newMaxFileSize;
        }
      }
      let newMaxResellerFileSize = this.parseDiskSize(
        this.editMaxResellerFileSize
      );
      if (!isNaN(newMaxResellerFileSize)) {
        payload.maxResellerFileSize = newMaxResellerFileSize;
      }
      this.$store
        .dispatch("organisations/create", payload)
        // .then((/* result */) => {
        //   // TODO: why an auth/validate ?!?
        //   return this.$store.dispatch("auth/validate");
        // })
        .then((result) => {
          // Removing all errors from server.
          this.$v.$touch();
          // Setting component status to success.
          this.setStatusCreateSuccess();
          this.$store.commit("status/showSuccess");
          if (result) {
            this.$router.push({
              name: "organisations-show",
              params: {
                id: result,
              },
            });
          } else {
            this.$router.push({
              name: "organisations-index",
            });
          }
        })
        .catch((/* error */) => {
          // Setting the appropriate error markers from the server response.
          this.$v.$touch();
          // Setting component status to failure.
          this.setStatusCreateError();
          this.$store.commit("status/showError");
        });
    },
    sendCreateWithFile() {
      this.setStatusCreating();
      const reader = new FileReader();
      reader.onload = (event) => {
        this.sendCreate(event);
      };
      reader.readAsDataURL(this.editUploads);
    },
  },
};
</script>
